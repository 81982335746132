import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import polaroid from '../../../images/PP_Care_polaroid.png'

const metaTags = {
  description: 'Post-Procedure care tips you can provide to parents and caregivers.',
  keywords: 'Homepage',
  title: 'Post-Procedure Care | SUPPRELIN® LA (histrelin acetate)'
}

// const externalLinksMetaTags = {
//   category: 'External Link',
//   action: 'Click',
//   label: 'Deerfield Homepage',
// }

const reference = (
  <>
    <p><strong>Reference: 1.</strong> SUPPRELIN<sup>&reg;</sup> LA [package insert]. Endo USA, Inc.</p>
  </>
)

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true} reference={reference}>
    <Row>
      <Col xs={12}>
        <h1>Post-procedure care</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <h2>Children should have regular visits with a pediatric endocrinologist while undergoing treatment with <nobr>SUPPRELIN<sup>&reg;</sup> LA</nobr>.</h2>
        <p>Monitor patients for signs of puberty<sup>1</sup>:</p>
        <ul className='brand-list'>
          <li>Blood tests (1 month post-implantation, and every 6 months thereafter)</li>
          <li>Height and bone age should be assessed every 6-12 months</li>
        </ul>
        <h2> Please share the following post-procedure care points with caregivers<sup>1</sup>:</h2>
        <ul className='brand-list'>
          <li>Patients should not remove the bandage for at least 24 hours to help ensure the incision heals properly </li>
          <li>While bandaged, the child&rsquo;s arm should be kept clean and dry, and he or she should avoid swimming or bathing for 24 hours</li>
          <li>Do not remove surgical strips; they will fall off on their own after several days </li>
          <li>Caregivers should monitor the incision site until it has fully healed and report any severe pain, redness, or swelling in and around the implant site. Infrequently, SUPPRELIN<sup>&reg;</sup> LA may be expelled from the body through the original incision site, rarely without the patient noticing </li>
          <li>Patients should refrain from strenuous physical activity with the affected arm for 7 days to allow the incision to fully close </li>
          <li>After the incision has healed, the child can resume normal activities</li>
          <li>Patients should return for routine checks of their condition and to ensure that the implant is present and functioning properly</li>
        </ul>
      </Col>
      <Col xs={12} md={6}>
        <img src={polaroid} alt="Polaroid 2" title="Polaroid 2" />
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/bb39c5e5-b03c-40fb-b52b-3110919e08ff/bb39c5e5-b03c-40fb-b52b-3110919e08ff_source__v.pdf"
          LinkCaption="Download the SUPPRELIN<sup>&reg;</sup> LA caregiver brochure: important information for patients - post prodcedure care"
        >
          Download the SUPPRELIN<sup>&reg;</sup> LA Caregiver Brochure: important information for patients
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/caregiver-resources"
          LinkCaption="View videos designed to help your patients understand CPP and SUPPRELIN<sup>&reg;</sup> LA - post prodcedure care"
        >
          View videos designed to help your patients understand CPP and SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage;
